import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionCavaliers = ( ) => {
  return (
    <Section title="Pour les cavaliers">
      <Link className="text-gray-900 text-2xl font-bold hover:text-black" to="/cavaliers">
        Développer votre cheval intérieur
      </Link>
    </Section>
  );
};

export default SectionCavaliers;
