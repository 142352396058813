import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionAtEnfants = ( ) => {
  return (
    <Section title="Ateliers pour enfants">
      <Link className="text-gray-900 font-bold text-2xl hover:text-black" to="/ateliers-enfants">
        Ni hérisson, ni paillasson
      </Link>
    </Section>
  );
};

export default SectionAtEnfants;
