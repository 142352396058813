import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionAboutEqui = ( ) => {
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;
  return (

    <Section title="equicoaching">
    <b>L'équicoaching est un accompagnement en présence du cheval, c'est du coaching guidé par le cheval qui fait miroir des émotions.</b><br/>
    C'est une méthode originale, rapide et efficace.<br/><br/>
    L'équicoaching n'est pas réservé aux cavaliers mais à tous les êtres humains en quête de sens, voulant avancer dans leur vie, se retrouver, mieux se connaitre et être soi.<br/><br/>
    
    Ce que l'équicoaching peut aborder:<br/>
    * Burn-out, dépression<br/>
    * Manque de confiance en soi<br/>
    * Problème relationnel<br/>
    * Traumatismes<br/>
    * Phobies<br/>
    * Objectifs professionnels<br/>
    * Meilleure connaissance de soi et gestion des émotions.<br/>
    * Angoisses et anxiétés <br/><br/>

    {prereq}<br/><br/>
    <b>Pourquoi le cheval ?</b> Parce que ses capacités sensorielles sont exceptionnelles et lui permettent d'analyser finement son environnement. Dans la nature, le cheval est une proie et  doit  pouvoir repérer le moindre mouvement suspect. Cette capacité lui permet de percevoir l'augmentation de votre rythme cardiaque, il sait ressentir immédiatement ce que vous émettez et vous le fait savoir par un feed-back comportemental direct. C'est un véritable miroir de vos émotions.<br/><br/>
    Le cheval ne ment pas et surtout il ne juge pas. <br/><br/>
    <b>Le coach</b> n'est pas là pour donner les réponses mais pour aider la personne à trouver ses propres solutions. La personne prend conscience de ses forces et faiblesses et découvre ce qui la motive et ce qui la limite. Ce questionnement lui permet de révéler son propre potentiel. <br/>
    Le coach ne soigne pas mais il aide à trouver son autonomie et à s'aider soi-même. <br/><br/>
    <b>Les séances se déroulent dans les régions de Marche-en-famenne, Tenneville et Rendeux.</b>
    </Section>
  );
};

export default SectionAboutEqui;
