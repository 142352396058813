import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionAdultes = ( ) => {
  return (
    <Section title="Adultes">
      <Link className="text-gray-900 text-2xl font-bold hover:text-black" to="/adultes">
        Séances équicoaching adultes
      </Link>
    </Section>
  );
};

export default SectionAdultes;
