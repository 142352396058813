import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionParentEnfant = ( ) => {
  return (
    <Section title="Un parent et son enfant">
      <Link className="text-gray-900 font-bold text-2xl hover:text-black" to="/parent-enfant">
        Dans la peau de l'autre
      </Link>
    </Section>
  );
};

export default SectionParentEnfant;
