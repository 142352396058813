import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SectionBlog from '../components/section-blog';
import SectionAdultes from '../components/section-adultes';
import SectionEnfants from '../components/section-enfants';
import SectionAboutEqui from '../components/section-about-equi';
import SectionContact from '../components/section-contact';
import SectionAtEnfant from '../components/section-at-enfant';
import SectionAdo from '../components/section-ado';
import SectionTemoignage from '../components/section-temoignages';
import SectionParentEnfant from '../components/section-parent-enfant';
import SectionCavaliers from '../components/section-cavaliers';
import SEO from '../components/seo';

const Index = ({ data }) => {
  const about = get(data, 'site.siteMetadata.about', false);
  const adultes = get(data, 'site.siteMetadata.adultes', false);
  const enfants = get(data, 'site.siteMetadata.enfants', false);
  const posts = data.allMarkdownRemark.edges;
  const noBlog = !posts || !posts.length;
  const contact = get(data, 'site.siteMetadata.contact', false);
  const atEnfant = get(data, 'site;siteMetadata.atEnfant', false);
  const ado = get(data, 'site.siteMetadata.ado', false);
  const tem = get(data, 'site.siteMetadata.tem', false);
  const parentenfant = get(data, 'site.siteMetadata.pe', false);
  const cavaliers = get(data, 'site.siteMetadata.cavaliers', false);
  
  return (
    <Layout>
      <SEO />
      <Header metadata={data.site.siteMetadata} noBlog={noBlog} />
      {about && <SectionAboutEqui about={about}/>}
      {!noBlog && <SectionBlog posts={posts} />}
      {adultes && <SectionAdultes adultes={adultes} />}
      {about && <SectionAdo about={ado} />}
      {enfants && <SectionEnfants enfants={enfants} />}
      {about && <SectionAtEnfant about={atEnfant} />}
      {about && <SectionParentEnfant about={parentenfant} />}
      {about && <SectionCavaliers about={cavaliers} />}
      {about && <SectionTemoignage about={tem} />}
      {about && <SectionContact about={contact} />}
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        adultes
        enfants
        linkedin
        projects {
          name
          description
          link
        }
        experience {
          name
          description
          link
        }
        skills {
          name
          description
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
