import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionEnfants = ( ) => {
  return (
    <Section title="Enfants">
      <Link className="text-gray-900 font-bold text-2xl hover:text-black" to="/enfants">
        Séances équicoaching enfants (6-12 ans)
      </Link>
    </Section>
  );
};

export default SectionEnfants;
