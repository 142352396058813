import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionTemoignages = ( ) => {
  return (
    <Section title="Témoignages">
      <Link className="text-gray-900 font-bold text-2xl hover:text-black" to="/temoignages">
        Témoignages
      </Link>
    </Section>
  );
};

export default SectionTemoignages;
