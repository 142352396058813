import { Link } from 'gatsby';
import React from 'react';

import Section from '../section';
//import SummaryItem from '../summary-item';

const SectionAdo = ( ) => {
  return (
    <Section title="Adolescents">
      <Link className="text-gray-900 font-bold text-2xl hover:text-black" to="/adolescents">
        Qui suis-je? <br/>
        Je révèle ma sexualité
      </Link>
    </Section>
  );
};

export default SectionAdo;
